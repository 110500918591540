import React from "react";
import placental_four from "../images/placentalCreamIcon.jpg";
import {NavLink} from "react-router-dom";
import axios from "axios";
import {getUrls} from "./Config";
import AlertWarningUserDataNotValid from './helpers/AlertWarningUserDataNotValid'
import AlertWarningUserDataNotValidText from './helpers/AlertWarningUserDataNotValidText'
import BreadCrumbIcon from "./helpers/BreadCrumbIcon";
import {productData} from "./data/ProductData";
import placentalSerum from "../images/placentalSerumIcon.jpg";
import placentalSet from "../images/placentalSetIcon.jpg";
import exosomesSerumIcon from "../images/exosomesSerumIcon.jpg";
import serumExosomes from "../images/serumExosomes.jpg";
import creamSerumExosomes from "../images/creamSerumExosomes.jpg";
import creamExosomes from "../images/creamExosomes.jpg";
import {QuestionCircleOutlined} from "@ant-design/icons";
import { Tooltip } from 'antd';


class Cart extends React.Component{
    constructor(props){
        super(props);
        this.state={
            discountCode: 'PL19FB',
            discountSize: .25,
            userDataValid: true,
            paymentMethod: 'BANKING',
            discount: "",
            urls: getUrls(),
            totalPrice: 0,
            allowPayment: false,
        }
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange = event => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
            }, () => {
                if (this.state.discount === this.state.discountCode){
                    this.props.handleDiscount();
                    this.countTotalAmount();
                }else{
                    this.props.revertDiscount();
                    this.countTotalAmount();
                }
            }
        );
    };

    validateUserData = () => {
        let username = localStorage.getItem(
            'username'
        );

        localStorage.setItem(
            'userDataValid',
            'false'
        );

        if (username !== null){
            axios.get(
                this.state.urls.environment.development +
                this.state.urls.apiVersion +
                this.state.urls.user.validateUserData +
                username
            ).then((response) => {
                this.setState({
                    userDataValid: response.data
                }, () => {
                    localStorage.setItem(
                        'userDataValid',
                        response.data
                    );
                });
            }).catch(
                (error) => {
                    console.log(error);
                }
            );
        } else {
            this.setState({
                userDataValid: true
            });

            localStorage.setItem(
                'userDataValid',
                'true'
            );
        }
    }

    allowPayment = () => {
        let allowPayment = false;
        let cart = JSON.parse(localStorage.getItem(
                "cartItems"
            ));

        if (cart !== null){
            cart = cart["count"];
        }

        if (cart >= 1){
            allowPayment = true;
        }

        this.setState({
            allowPayment: allowPayment
        });
    }

    setPaymentMethod = (value) => {
        this.setState({
            paymentMethod: value
        });

        localStorage.setItem(
            'paymentMethod',
            value
        );
    }

    checkPaymentMethod = () => {
        let paymentMethod = "BANKING"
        const selectedPaymentMethod = localStorage.getItem('paymentMethod');

        if (selectedPaymentMethod === null || selectedPaymentMethod === 'undefined'){
            return paymentMethod;
        }

        return selectedPaymentMethod;
    }

    getCartItems = () => {
        return JSON.parse(localStorage.getItem(
            "cartItems"
            )
        );
    }

    getCartItemsByProduct = (product) => {
       let cart = this.getCartItems();

        if(cart){
            return cart[product];
        }

        return null;
    }

    countTotalAmount = () => {
        let total = 0;
        let items = this.getCartItems();

        if (items){
            Object.entries(items).forEach(item => {
                if (item[0] === "cream"){
                    total += item[1]*119;
                }

                if (item[0] === "serum"){
                    total += item[1]*139;
                }

                if (item[0] === "exosomes"){
                    total += item[1]*199;
                }

                if (item[0] === "set"){
                    total += item[1]*199;
                }

                if (item[0] === "setExosomesCreamSerum"){
                    total += item[1]*349;
                }

                if (item[0] === "setExosomesCream"){
                    total += item[1]*259;
                }

                if (item[0] === "setExosomesSerum"){
                    total += item[1]*279;
                }
            });

            if (localStorage.getItem('discount') !== null){
                let totalAfterDiscount = parseFloat(total);
                total = totalAfterDiscount - totalAfterDiscount * .25;
            }
        }

        this.setState({
            totalPrice: total
        });

        return total;
    }

    isDiscountSet(){
        if(localStorage.getItem('discount') !== null){
            this.setState({
                discount: "PL19FB"
            });
        }
    }

    addCream = () => {
        this.props.add_to_cart('cream');
        this.countTotalAmount();
        this.allowPayment();
    }

    removeCream = () => {
        this.props.remove_from_cart("cream");
        this.countTotalAmount();
        this.allowPayment();
    }

    addExosomes = () => {
        this.props.add_to_cart('exosomes');
        this.countTotalAmount();
        this.allowPayment();
    }


    removeExosomes = () => {
        this.props.remove_from_cart("exosomes");
        this.countTotalAmount();
    }

    addSerum = () => {
        this.props.add_to_cart('serum');
        this.countTotalAmount();
        this.allowPayment();
    }

    removeSerum = () => {
        this.props.remove_from_cart("serum");
        this.countTotalAmount();
        this.allowPayment();
    }

    addSet = () => {
        this.props.add_to_cart('set');
        this.countTotalAmount();
        this.allowPayment();
    }

    removeSet = () => {
        this.props.remove_from_cart("set")
        this.countTotalAmount();
        this.allowPayment();
    }

    addSetExosomesCreamSerum = () => {
        this.props.add_to_cart('setExosomesCreamSerum');
        this.countTotalAmount();
        this.allowPayment();
    }

    removeSetExosomesCreamSerum = () => {
        this.props.remove_from_cart("setExosomesCreamSerum")
        this.countTotalAmount();
        this.allowPayment();
    }

    addSetExosomesCream = () => {
        this.props.add_to_cart('setExosomesCream');
        this.countTotalAmount();
        this.allowPayment();
    }

    removeSetExosomesCream = () => {
        this.props.remove_from_cart("setExosomesCream")
        this.countTotalAmount();
        this.allowPayment();
    }

    addSetExosomesSerum = () => {
        this.props.add_to_cart('setExosomesSerum');
        this.countTotalAmount();
        this.allowPayment();
    }

    removeSetExosomesSerum = () => {
        this.props.remove_from_cart("setExosomesSerum")
        this.countTotalAmount();
        this.allowPayment();
    }


    componentDidMount() {
        this.validateUserData();
        // this.props.revertDiscount();
        this.setPaymentMethod(this.checkPaymentMethod());
        this.countTotalAmount();
        this.isDiscountSet();
        this.allowPayment();
    }

    render() {
        return (
            <div className={"content"}>
                { this.state.userDataValid ? null : <AlertWarningUserDataNotValid/> }
                <div className={'row p-4'}>
                    <div className={'col-12 breadcrumbs mb-4'}>
                        <small>
                            <BreadCrumbIcon />
                            <NavLink className={'text-muted'}
                                     to={'/'}>Titulinis</NavLink>
                            <i className="fa fa-angle-right text-muted"></i>
                            <NavLink className={'text-muted ml-2'}
                                     to={'/cart'}>Prekių krepšelis</NavLink>
                        </small>
                    </div>
                </div>

                <div className={'row p-4'}>
                    <div className={'col-12'}>
                        <h3 className="font-weight-light mb-2">Prekių krepšelis</h3>
                    </div>
                </div>

                <div className={'row pb-0 pl-4 pr-4'}>
                    <div className={'col-12'}>
                        <p className={'mb-0 text-secondary'}>Kremai ir serumai</p>
                    </div>
                </div>
                <div className={'row p-4'}>
                    <div className={'col-sm'}>
                        <div className={'cart'}>
                            <img className={'mr-3 mt-2 mb-2 float-left'}
                                 src={exosomesSerumIcon}
                                 alt={'Placental cart item' }
                                 width={'72px'}
                                 height={'72px'} />
                            <p className={'mb-0'}>{productData[0].exosomes.name}</p>
                            <p className={'d-block mb-3'}>{productData[0].exosomes.price}€</p>
                            <button className="btn btn-sm btn-light pt-0 pb-0"
                                    onClick={() => this.addExosomes()}>
                                <span className="fa fa-cart-arrow-down mr-2"></span>
                                <small>Pridėti</small>
                            </button>
                        </div>
                    </div>

                    <div className={'col-sm'}>
                        <div className={'cart'}>
                            <img className={'mr-3 mt-2 mb-2 float-left'}
                                 src={placental_four}
                                 alt={'Placental cart item'}
                                 width={'72px'}
                                 height={'72px'}/>
                            <p className={'mb-0'}>{productData[0].cream.name}</p>
                            <p className={'d-block mb-3'}>{productData[0].cream.price}€</p>
                            <small className={"text-danger"}>Out of stock</small>
                            <button
                                disabled
                                className="btn btn-sm btn-light pt-0 pb-0"
                                onClick={() => this.addCream()}>
                                <span className="fa fa-cart-arrow-down mr-2"></span>
                                <small>Pridėti</small>
                            </button>
                        </div>
                    </div>

                    <div className={'col-sm'}>
                        <div className={'cart'}>
                            <img className={'mr-3 mt-2 mb-2 float-left'}
                                 src={placentalSerum}
                                 alt={'Placental cart item'}
                                 width={'72px'}
                                 height={'72px'}/>
                            <p className={'mb-0'}>{productData[0].serum.name}</p>
                            <p className={'d-block mb-3'}>{productData[0].serum.price}€</p>
                            <small className={"text-danger"}>Out of stock</small>
                            <button
                            disabled
                                className="btn btn-sm btn-light pt-0 pb-0"
                                onClick={() => this.addSerum()}>
                                <span className="fa fa-cart-arrow-down mr-2"></span>
                                <small>Pridėti</small>
                            </button>
                        </div>
                    </div>

                    <div className={'col-sm'}>
                        <div className={'cart'}>
                            <img className={'mr-3 mt-2 float-left'}
                                 src={placentalSet}
                                 alt={'Placental cart item'}
                                 width={'72px'}
                                 height={'72px'}/>
                            <p className={'mb-0'}>{productData[0].set.name}</p>
                            <p className={'d-block mb-3'}>{productData[0].set.price}€</p>
                            <small className={"text-danger"}>Out of stock</small>
                            <button
                                disabled
                                className="btn btn-sm btn-light pt-0 pb-0"
                                onClick={() => this.addSet()}>
                                <span className="fa fa-cart-arrow-down mr-2"></span>
                                <small>Pridėti</small>
                            </button>
                        </div>
                    </div>
                </div>

                <div className={'row pb-0 pl-4 pr-4'}>
                    <div className={'col-12'}>
                        <p className={'mb-0 text-secondary'}>Egzosomų, kremų ir serumų rinkiniai</p>
                    </div>
                </div>

                <div className={"row p-4"}>
                    <div className={'col-sm'}>
                        <div className={'cart'}>
                            <img className={'mr-3 mt-2 float-left'}
                                 src={creamSerumExosomes}
                                 alt={'Placental cart item'}
                                 width={'104px'}
                                 height={'72px'}/>
                            <p className={'mb-0'}>{productData[0].setExosomesCreamSerum.name}</p>
                            <p className={'d-block mb-3'}>{productData[0].setExosomesCreamSerum.price}€</p>
                            <small className={"text-danger"}>Out of stock</small>
                            <button
                                disabled
                                className="btn btn-sm btn-light pt-0 pb-0"
                                onClick={() => this.addSetExosomesCreamSerum()}>
                                <span className="fa fa-cart-arrow-down mr-2"></span>
                                <small>Pridėti</small>
                            </button>
                        </div>
                    </div>

                    <div className={'col-sm'}>
                        <div className={'cart'}>
                            <img className={'mr-3 mt-2 float-left'}
                                 src={creamExosomes}
                                 alt={'Placental cart item'}
                                 width={'104px'}
                                 height={'72px'}/>
                            <p className={'mb-0'}>{productData[0].setExosomesCream.name}</p>
                            <p className={'d-block mb-3'}>{productData[0].setExosomesCream.price}€</p>
                            <small className={"text-danger"}>Out of stock</small>
                            <button
                                disabled
                                className="btn btn-sm btn-light pt-0 pb-0"
                                onClick={() => this.addSetExosomesCream()}>
                                <span className="fa fa-cart-arrow-down mr-2"></span>
                                <small>Pridėti</small>
                            </button>
                        </div>
                    </div>

                    <div className={'col-sm'}>
                        <div className={'cart'}>
                            <img className={'mr-3 mt-2 float-left'}
                                 src={serumExosomes}
                                 alt={'Placental cart item'}
                                 width={'104px'}
                                 height={'72px'}/>
                            <p className={'mb-0'}>{productData[0].setExosomesSerum.name}</p>
                            <p className={'d-block mb-3'}>{productData[0].setExosomesSerum.price}€</p>
                            <small className={"text-danger"}>Out of stock</small>
                            <button
                                disabled
                                className="btn btn-sm btn-light pt-0 pb-0"
                                onClick={() => this.addSetExosomesSerum()}>
                                <span className="fa fa-cart-arrow-down mr-2"></span>
                                <small>Pridėti</small>
                            </button>
                        </div>
                    </div>
                </div>

                <div className={'row p-4'}>
                    <div className="col-12">
                        <h4 className={'font-weight-light mb-2'}>Jūsų krepšelis</h4>

                        <p className={'mb-5 text-muted'}>Prekės pristatomos per 1-2 darbo dienas.</p>

                        <div className={
                                localStorage.getItem("cartItems") === null ||
                                JSON.parse(localStorage.getItem("cartItems")).count === 0 ? '' : 'd-none'}>
                            <h4 className={"font-weight-light"}> Jūsų krepšelis tuščias.</h4>
                        </div>


                        <table className={"table table-borderless shadow " + (
                            this.getCartItemsByProduct('count') === null ||
                            this.getCartItemsByProduct('count') === 0 ?
                                'd-none' : '')} >
                            <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Prekės pavadinimas</th>
                                    <th scope="col">Kiekis</th>
                                    <th scope="col">Kaina</th>
                                    <th scope="col">Suma</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr className={
                                    (this.getCartItemsByProduct('cream') === null ||
                                    this.getCartItemsByProduct('cream') === 0 ? 'd-none' : '')}>
                                    <th className={'align-middle'}
                                        scope="row">
                                        #
                                    </th>
                                    <td className={'align-middle'}>
                                        <img className={'mr-3 mt-2 mb-2 d-inline-block'}
                                             src={placental_four}
                                             alt={'Placental cart item' }
                                             width={'64px'}
                                             height={'64px'}
                                        />
                                        <span className={'pt-3'}>Placental Face Cream</span></td>
                                    <td className={'align-middle'}>
                                        <div className={'float-left mr-3 ml-2'}
                                             style={{marginTop: '6px'}}>
                                            {this.getCartItemsByProduct('cream')}
                                        </div>
                                        <div className={'float-left add-remove-cart'}>
                                            <i className="fa fa-angle-up d-block"
                                               onClick={() => this.addCream()}>
                                            </i>
                                            <i className="fa fa-angle-down"
                                               onClick={() => this.removeCream()}>
                                            </i>
                                        </div>
                                    </td>
                                    <td className={'align-middle'}>
                                        {this.props.price}€
                                    </td>
                                    <td className={'align-middle'}>
                                        {this.props.price * this.getCartItemsByProduct('cream')}€
                                    </td>
                                </tr>
                                <tr className={
                                    (this.getCartItemsByProduct('serum') === 0 ||
                                     this.getCartItemsByProduct('serum') === null ? 'd-none' : '')}>
                                    <th className={'align-middle'}
                                        scope="row">
                                        #
                                    </th>
                                    <td className={'align-middle'}>
                                        <img className={'mr-3 mt-2 mb-2 d-inline-block'}
                                             src={placentalSerum}
                                             alt={'Placental cart item' }
                                             width={'64px'}
                                             height={'64px'}
                                        />
                                        <span className={'pt-3'}>
                                            {productData[0].serum.name}
                                        </span>
                                    </td>
                                    <td className={'align-middle'}>
                                        <div className={'float-left mr-3 ml-2'}
                                             style={{marginTop: '6px'}}>
                                            {this.getCartItemsByProduct('serum')}
                                        </div>
                                        <div className={'float-left add-remove-cart'}>
                                            <i className="fa fa-angle-up d-block"
                                               onClick={() => this.addSerum()}>
                                            </i>
                                            <i className="fa fa-angle-down"
                                               onClick={() => this.removeSerum()}>
                                            </i>
                                        </div>
                                    </td>
                                    <td className={'align-middle'}>
                                        {productData[0].serum.price}€
                                    </td>
                                    <td className={'align-middle'}>
                                        {productData[0].serum.price * this.getCartItemsByProduct('serum')}€
                                    </td>
                                </tr>
                                <tr className={
                                    (this.getCartItemsByProduct('exosomes') === 0 ||
                                    this.getCartItemsByProduct('exosomes') === null ? 'd-none' : '')}>
                                    <th className={'align-middle'}
                                        scope="row">
                                        #
                                    </th>
                                    <td className={'align-middle'}>
                                        <img className={'mr-3 mt-2 mb-2 d-inline-block'}
                                             src={exosomesSerumIcon}
                                             alt={'Exosomes Serum' }
                                             width={'64px'}
                                             height={'64px'}
                                        />
                                        <span className={'pt-3'}>
                                            {productData[0].exosomes.name}
                                        </span>
                                    </td>
                                    <td className={'align-middle'}>
                                        <div className={'float-left mr-3 ml-2'}
                                             style={{marginTop: '6px'}}>
                                            {this.getCartItemsByProduct('exosomes')}
                                        </div>
                                        <div className={'float-left add-remove-cart'}>
                                            <i className="fa fa-angle-up d-block"
                                               onClick={() => this.addExosomes()}>
                                            </i>
                                            <i className="fa fa-angle-down"
                                               onClick={() => this.removeExosomes()}>
                                            </i>
                                        </div>
                                    </td>
                                    <td className={'align-middle'}>
                                        {productData[0].exosomes.price}€
                                    </td>
                                    <td className={'align-middle'}>
                                        {productData[0].exosomes.price * this.getCartItemsByProduct('exosomes')}€
                                    </td>
                                </tr>
                                <tr className={
                                    (this.getCartItemsByProduct('set') === 0 ||
                                    this.getCartItemsByProduct('set') === null ? 'd-none' : '')}>
                                    <th className={'align-middle'}
                                        scope="row">
                                        #
                                    </th>
                                    <td className={'align-middle'}>
                                        <img className={'mr-3 mt-2 mb-2 d-inline-block'}
                                             src={placentalSet}
                                             alt={'Placental cart item'}
                                             width={'64px'}
                                             height={'64px'}
                                        />
                                        <span className={'pt-3'}>
                                            {productData[0].set.name}
                                        </span>
                                    </td>
                                    <td className={'align-middle'}>
                                        <div className={'float-left mr-3 ml-2'}
                                             style={{marginTop: '6px'}}>
                                            {this.getCartItemsByProduct('set')}
                                        </div>
                                        <div className={'float-left add-remove-cart'}>
                                            <i className="fa fa-angle-up d-block"
                                               onClick={() => this.addSet()}>
                                            </i>
                                            <i className="fa fa-angle-down"
                                               onClick={() => this.removeSet()}>
                                            </i>
                                        </div>
                                    </td>
                                    <td className={'align-middle'}>
                                        {productData[0].set.price}€
                                    </td>
                                    <td className={'align-middle'}>
                                        {productData[0].set.price * this.getCartItemsByProduct('set')}€
                                    </td>
                                </tr>

                                <tr className={
                                    (this.getCartItemsByProduct('setExosomesCreamSerum') === 0 ||
                                    this.getCartItemsByProduct('setExosomesCreamSerum') === null ? 'd-none' : '')}>
                                    <th className={'align-middle'}
                                        scope="row">
                                        #
                                    </th>
                                    <td className={'align-middle'}>
                                        <img className={'mr-3 mt-2 mb-2 d-inline-block'}
                                             src={creamSerumExosomes}
                                             alt={'Placental cart item'}
                                             width={'64px'}
                                             height={'64px'}
                                        />
                                        <span className={'pt-3'}>
                                            {productData[0].setExosomesCreamSerum.name}
                                        </span>
                                    </td>
                                    <td className={'align-middle'}>
                                        <div className={'float-left mr-3 ml-2'}
                                             style={{marginTop: '6px'}}>
                                            {this.getCartItemsByProduct('setExosomesCreamSerum')}
                                        </div>
                                        <div className={'float-left add-remove-cart'}>
                                            <i className="fa fa-angle-up d-block"
                                               onClick={() => this.addSetExosomesCreamSerum()}>
                                            </i>
                                            <i className="fa fa-angle-down"
                                               onClick={() => this.removeSetExosomesCreamSerum()}>
                                            </i>
                                        </div>
                                    </td>
                                    <td className={'align-middle'}>
                                        {productData[0].setExosomesCreamSerum.price}€
                                    </td>
                                    <td className={'align-middle'}>
                                        {productData[0].setExosomesCreamSerum.price * this.getCartItemsByProduct('setExosomesCreamSerum')}€
                                    </td>
                                </tr>

                                <tr className={
                                    (this.getCartItemsByProduct('setExosomesCream') === 0 ||
                                    this.getCartItemsByProduct('setExosomesCream') === null ? 'd-none' : '')}>
                                    <th className={'align-middle'}
                                        scope="row">
                                        #
                                    </th>
                                    <td className={'align-middle'}>
                                        <img className={'mr-3 mt-2 mb-2 d-inline-block'}
                                             src={creamExosomes}
                                             alt={'Placental cart item'}
                                             width={'64px'}
                                             height={'64px'}
                                        />
                                        <span className={'pt-3'}>
                                            {productData[0].setExosomesCream.name}
                                        </span>
                                    </td>
                                    <td className={'align-middle'}>
                                        <div className={'float-left mr-3 ml-2'}
                                             style={{marginTop: '6px'}}>
                                            {this.getCartItemsByProduct('setExosomesCream')}
                                        </div>
                                        <div className={'float-left add-remove-cart'}>
                                            <i className="fa fa-angle-up d-block"
                                               onClick={() => this.addSetExosomesCream()}>
                                            </i>
                                            <i className="fa fa-angle-down"
                                               onClick={() => this.removeSetExosomesCream()}>
                                            </i>
                                        </div>
                                    </td>
                                    <td className={'align-middle'}>
                                        {productData[0].setExosomesCream.price}€
                                    </td>
                                    <td className={'align-middle'}>
                                        {productData[0].setExosomesCream.price * this.getCartItemsByProduct('setExosomesCream')}€
                                    </td>
                                </tr>

                                <tr className={
                                    (this.getCartItemsByProduct('setExosomesSerum') === 0 ||
                                    this.getCartItemsByProduct('setExosomesSerum') === null ? 'd-none' : '')}>
                                    <th className={'align-middle'}
                                        scope="row">
                                        #
                                    </th>
                                    <td className={'align-middle'}>
                                        <img className={'mr-3 mt-2 mb-2 d-inline-block'}
                                             src={serumExosomes}
                                             alt={'Placental cart item'}
                                             width={'64px'}
                                             height={'64px'}
                                        />
                                        <span className={'pt-3'}>
                                            {productData[0].setExosomesSerum.name}
                                        </span>
                                    </td>
                                    <td className={'align-middle'}>
                                        <div className={'float-left mr-3 ml-2'}
                                             style={{marginTop: '6px'}}>
                                            {this.getCartItemsByProduct('setExosomesSerum')}
                                        </div>
                                        <div className={'float-left add-remove-cart'}>
                                            <i className="fa fa-angle-up d-block"
                                               onClick={() => this.addSetExosomesSerum()}>
                                            </i>
                                            <i className="fa fa-angle-down"
                                               onClick={() => this.removeSetExosomesSerum()}>
                                            </i>
                                        </div>
                                    </td>
                                    <td className={'align-middle'}>
                                        {productData[0].setExosomesSerum.price}€
                                    </td>
                                    <td className={'align-middle'}>
                                        {productData[0].setExosomesSerum.price * this.getCartItemsByProduct('setExosomesSerum')}€
                                    </td>
                                </tr>

                            </tbody>
                        </table>
                    </div>
                </div>
                <div className={"row p-4 " + (this.getCartItemsByProduct('count') === null ||
                this.getCartItemsByProduct('count') === 0 ?
                    'd-none' : '')}>
                    <div className="col-md-4 mb-5">
                        <h4 className={'mb-3 font-weight-light'}>Pristatymas</h4>
                        <p>Prekių pristatymas nemokamas. Prekės pristatomos per 1-2 darbo dienas.</p>
                    </div>
                    <div className="col-md-4 mb-5">
                        <h4 className={'mb-3 font-weight-light'}>Nuolaidos</h4>
                        <p>Įveskite nuolaidos kodą. Nuolaidų ieškokite mūsų
                            <a href={"https://www.facebook.com/Placental-1589459521318203"}
                               target={"_blank"}>
                            <span className={"text-primary la la-facebook"}/>
                            </a> paskyroje.</p>
                        <input type="text"
                               name={"discount"}
                               style={{maxWidth: '320px'}}
                               onChange={this.handleChange}
                               className="form-control form-control-lg"
                               value={this.state.discount}
                               placeholder="Nuolaidos kodas"
                        />
                    </div>
                    <div className="col-md-4 mb-5">
                        <h4 className={'font-weight-light'}>Atsiskaitymo metodai</h4>
                        <p className="ml-3 pl-1 d-block">
                            <span className="form-check pl-3 mt-3 mb-2" role="button"
                                onClick={() => this.setPaymentMethod("INTEGRATED")}
                            >
                                <input
                                    className="form-check-input"
                                    // disabled
                                    role="button"
                                    type="radio"
                                    onChange={this.handleChange}
                                    checked={(this.state.paymentMethod === 'INTEGRATED' ? 'checked' : '')}
                                />
                                <label
                                    role="button"
                                    className="form-check-label">
                                    Atsiskaitymas per svetainę &nbsp;
                                    <span className="badge badge-primary">Jau greitai!</span>
                                </label>
                            </span>
                            <span className="form-check pl-3 mb-2"
                                 role="button"
                                 onClick={() => this.setPaymentMethod("BANKING")}>
                                <input className="form-check-input"
                                       type="radio"
                                       role="button"
                                       onChange={this.handleChange}
                                       checked={(this.state.paymentMethod === 'BANKING' ? 'checked' : '')}
                                />
                                <label role="button"
                                       className="form-check-label">
                                    Savarankiškas mokėjimas per el. bankininkystę &nbsp;
                                    <Tooltip title="Mokėjimo siunčiami el. paštu po užsakymo patvirtinimo">
                                        <QuestionCircleOutlined />
                                    </Tooltip>
                                </label>
                            </span>
                            <span className="form-check pl-3 mb-3"
                                 role="button"
                                 onClick={() => this.setPaymentMethod("CASH")}>
                                <input className="form-check-input"
                                       type="radio"
                                       role="button"
                                       onChange={this.handleChange}
                                       checked={(this.state.paymentMethod === 'CASH' ? 'checked' : '')}
                                />
                                <label role="button"
                                       className="form-check-label">Grynieji pinigai
                                    <small className={'text-danger'}> (tik Vilniuje)</small>
                                </label>
                            </span>
                        </p>
                    </div>
                </div>
                <div className={"row pt-0 pr-4 pl-4"}>
                    <div className="col-12">
                        <h4 className={'font-weight-light'}>Apmokėjimas</h4>
                        <h5 className={"font-weight-light mt-3 text-primary"}>
                            Viso: {this.state.totalPrice.toFixed(2)}€
                        </h5>
                        <NavLink className={"btn btn-primary btn-lg mt-5 " + (this.state.allowPayment ? '' : 'disabled')}
                                 to={{
                                     pathname:"/checkout",
                                     paymentProps:{
                                         paymentMethod:this.state.paymentMethod
                                     }
                                 }}
                        >Apmokėti</NavLink>
                        {this.state.userDataValid ? null : <AlertWarningUserDataNotValidText/> }
                    </div>
                </div>
            </div>
        )
    }
}

export default Cart