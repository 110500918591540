import React from "react";
import placentalMain from "../../images/placentalMain.jpg";
import placentalGoesBlack from "../../images/placentalGoesBlack.jpg";
import placentalSerum from "../../images/placental_serum.jpg";
import ExosomesCarousel from "../../images/ExosomesCarousel.png";

class Carousel extends React.Component{
    render() {
        return (
            <div className={'row m-0'}>
                <div className={'col-lg-6 p-0'}>
                    <div className="bd-example">
                        <div id="carouselExampleCaptions"
                             className="carousel slide"
                             data-ride="carousel">
                            <ol className="carousel-indicators">
                                <li data-target="#carouselExampleCaptions"
                                    data-slide-to="0"
                                    className="active"></li>
                                <li data-target="#carouselExampleCaptions"
                                    data-slide-to="1"></li>
                                <li data-target="#carouselExampleCaptions"
                                    data-slide-to="2"></li>
                                <li data-target="#carouselExampleCaptions"
                                    data-slide-to="3"></li>
                            </ol>
                            <div className="carousel-inner">
                                <div className="carousel-item active"
                                     style={{backgroundImage: `url(${ExosomesCarousel})`}}>
                                    <img src={placentalGoesBlack}
                                         className="d-block w-100"
                                         alt="..." />
                                    <div className="carousel-caption d-none d-md-block">
                                        <h5>Ryto rasa. Magiška!</h5>
                                        <p>Veido serumas su Goji uogų ir Aloe Wera egzosomomis</p>
                                    </div>
                                </div>
                                <div className="carousel-item"
                                     style={{backgroundImage: `url(${placentalSerum})`}}>
                                    <img src={placentalGoesBlack}
                                         className="d-block w-100"
                                         alt="..." />
                                    <div className="carousel-caption d-none d-md-block">
                                        <h5>Placental Serumas. Unikalus!</h5>
                                        <p>Veido serumas su placentos ekstraktu, hialurono rūgštimi ir dviejų rūšių
                                            peptidais</p>
                                    </div>
                                </div>
                                <div className="carousel-item"
                                     style={{backgroundImage: `url(${placentalMain})`}}>
                                    <img src={placentalMain}
                                         className="d-block w-100"
                                         alt="..." />
                                    <div className="carouselPosition carousel-caption d-none d-md-block">
                                        <h5 className={"mb-0"}
                                            style={{"textShadow" : "2px 2px 4px  #000"}}>Atsinaujinęs
                                            <p className={"placental-title d-inline-block"}>Placental</p>
                                        </h5>
                                        <p style={{"textShadow" : "2px 2px 2px  #000"}}>Toks pat efektyvus ir kupinas
                                            naujų atspalvių</p>
                                    </div>
                                </div>
                                <div className="carousel-item"
                                     style={{backgroundImage: `url(${placentalGoesBlack})`}}>
                                    <img src={placentalGoesBlack}
                                         className="d-block w-100"
                                         alt="..." />
                                    <div className="carousel-caption d-none d-md-block">
                                        <h5>Placental Goes Black</h5>
                                        <p>Placental Goes  Black visada Limited Edition ir puikiai tinkantis nustebinti.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <a href="#carouselExampleCaptions"
                               className="carousel-control-prev"
                               role="button"
                               data-slide="prev">
                                <span className="carousel-control-prev-icon"
                                      aria-hidden="true"></span>
                                <span className="sr-only">Previous</span>
                            </a>
                            <a href="#carouselExampleCaptions"
                               className="carousel-control-next"
                               role="button"
                               data-slide="next">
                                <span className="carousel-control-next-icon"
                                      aria-hidden="true"></span>
                                <span className="sr-only">Next</span>
                            </a>
                        </div>
                    </div>
                </div>
                <div className={'col-lg-6 pt-5 pr-5 pl-5 pb-3 gradient-background text-center'}>
                    <div className={"best-price-carousel"}>
                    </div>
                    <div className={"best-price-text-carousel"}>
                        <span className={"fa fa-heart"}></span>
                    </div>
                    <h1 className={'d-inline-block mr-1 placental-title-big mb-4 mt-3'}>Placental</h1>
                    <h4 className={"font-weight-light d-inline-block"}>Visada geriausias pasirinkimas! Placental</h4>
                    <h4 className={'d-inline-block background-black text-white pr-3 pl-3 pb-1 ml-2 mr-2'}>kremas</h4>
                    <h4 className={"font-weight-light d-inline-block mr-2"}>kartu su Placental </h4>
                    <h4 className={'d-inline-block background-black text-white pr-3 pl-3 pb-1 mt-0 mr-2'}>serumu</h4>
                    <h4 className={"font-weight-light d-inline-block mr-2"}> - kainos ir kokybės garantas!</h4><br/>
                    <button
                        disabled
                        className="btn btn-sm transition-3d-hover background-retro-red text-white mt-4"
                        onClick={() => this.props.add_to_cart("set")}>
                        <span className="fa fa-cart-arrow-down mr-2"></span>
                        Į krepšelį
                    </button>
                    <small className={"text-danger d-block"}>Out of stock</small>
                </div>
            </div>
        );
    }
}

export default Carousel