import React from "react";
import {NavLink, withRouter} from "react-router-dom";
import BreadCrumbIcon from "../helpers/BreadCrumbIcon";

function PrivacyPolicy(){
        return (
            <div className={"content"}>
                <div className={'row p-4'}>
                    <div className={'col-12 breadcrumbs mb-4'}>
                        <small>
                            <BreadCrumbIcon />
                            <NavLink className={'text-muted'}
                                     to={'/'}>Titulinis</NavLink>
                            <i className="fa fa-angle-right text-muted"></i>
                            <NavLink className={'text-muted ml-2'}
                                     to={'/privacy'}>Privatumo politika</NavLink>
                        </small>
                    </div>
                </div>
                <div className={'row p-4'}>
                    <div className={'col-12'}>
                        <h3 className="font-weight-light mb-2">Privatumo politika</h3>

                        <h4 className={'font-weight-light mt-5 mb-3'}>Įžanga</h4>
                        <h6 className="font-weight-light text-justify"
                            style={{"line-height":"1.6"}}>
                            „Placental“ (e. komercijos valdytojas MB "Global Solutions Group") atsakingai saugo Jūsų privatumą.
                            Ši privatumo politika aprašo, kokią asmeninę informaciją mes renkame ir kaip mes ją naudojame.
                            Mūsų pagrindinis tikslas renkant Jūsų asmeninę informaciją yra suteikti Jums sklandžią,
                            efektyvią ir asmeninius poreikius tenkinančią patirtį, kol Jūs naudojatės mūsų elektronine
                            parduotuve. Tai leidžia mums teikti informaciją ir atlikti funkcijas, kurios labiausiai
                            atitinka Jūsų poreikius ir padeda pritaikyti mūsų paslaugas taip, kad jos būtų lengviau ir
                            greičiau suprantamos.
                        </h6>

                        <h4 className={'font-weight-light mt-5 mb-3'}>Vartojamos sąvokos</h4>
                        <h6 className="font-weight-light text-justify"
                            style={{"line-height":"1.6"}}>
                            Pardavėjas – „Placental“ (e. komercijos valdytojas MB "Global Solutions Group"), juridinio asmens kodas
                            305163972, PVM mokėtojo kodas LT100013775414, Algirdo g. 46-210, LT-03209 Vilnius <br />

                            Placental – elektroninė parduotuvė, esanti adresu www.placental.lt<br />

                            Naudotojas (pirkėjas) – bet kuris fizinis asmuo ar juridinis asmuo, kuris naudojasi
                            elektronine parduotuve, užsako ir ar perka prekes.<br />

                            Asmens duomenys – bet kuri informacija, susijusi su fiziniu asmeniu – duomenų subjektu,
                            kurio tapatybė yra žinoma arba gali būti tiesiogiai ar netiesiogiai nustatyta.<br />

                            Asmens duomenų valdytojas – www.placental.com<br />

                            Paskyra – Pirkėjo registracijos rezultatas, dėl kurio sukuriama jo asmeninius duomenis ir
                            užsakymą istoriją saugojanti sąskaita.<br />

                            Paslaugos – visos Placental Pirkėjui teikiamos paslaugos.<br />

                            Trečiasis asmuo – juridinis ar fizinis asmuo, išskyrus duomenų subjektą, duomenų valdytoją,
                            duomenų tvarkytoją ir asmenis, kurie yra tiesiogiai duomenų valdytojo ar duomenų tvarkytojo
                            įgalioti tvarkyti duomenis.<br />

                            Administratorius – už elektroninę parduotuvę www.placental.lt atsakingas asmuo.<br />

                            IP adresas – Kiekvienam prie interneto prijungtam kompiuteriui priskirtas unikalus numeris,
                            kuris žinomas kaip interneto protokolo (IP) adresas. Kadangi šie numeriai paprastai
                            priskiriami pagal šalies blokus, IP adresas gali būti dažnai naudojamas šaliai, kurioje
                            kompiuteris prijungiamas prie interneto, identifikuoti.<br />

                            Taisyklės – patvirtintos galiojančios “Prekių pirkimo – pardavimo internetinėje parduotuvėje
                            www.placental.lt Taisyklės”.<br />

                            Tiesioginė rinkodara – veikla, skirta paštu, telefonu arba kitokiu tiesioginiu būdu siūlyti
                            asmenims prekes ar paslaugas ir (arba) teirautis ją nuomonės dėl siūlomų Prekių ar
                            paslaugų.<br />

                            Kitos šioje privatumo politikoje vartojamos sąvokos suprantamos taip, kaip jos yra
                            apibrėžtos Lietuvos Respublikos asmens duomenų teisinės apsaugos įstatyme.
                        </h6>

                        <h4 className={'font-weight-light mt-5 mb-3'}>
                            Bendros nuostatos
                        </h4>
                        <h6 className="font-weight-light text-justify"
                            style={{"line-height":"1.6"}}>
                            Ši privatumo politika yra skirta užtikrinti, kad Pardavėjas laikosi Lietuvos Respublikos
                            asmens duomenų teisinės apsaugos įstatymo, Lietuvos Respublikos elektroninių ryšių įstatymo
                            ir kitų galiojančių teisės aktų, reglamentuojančių asmens duomenų apsaugą, taip pat
                            kompetentingų institucijų nurodymų.<br />

                            Placental internetinės parduotuvės privatumo politika numato pagrindines Asmens duomenų
                            rinkimo, kaupimo ir tvarkymo Taisyklės naudojantis www.placental.lt  siūlomomis Paslaugomis.
                            Privatumo politika skirta apsaugoti ir ginti Pirkėją bei jo Asmens duomenis nuo neteisėto
                            naudojimo. Privatumo politika taikoma ir www.placental.lt  Paskyroms.<br />

                            Internetinėje parduotuvėje yra nuorodų į kitų asmenų, įmonių ar organizacijų interneto
                            tinklalapius, ir kad Pardavėjas nėra atsakingas už tokių interneto tinklapių turinį ir/ar
                            ją naudojamus Privatumo politikos užtikrinimo metodus, todėl prieš pateikdamas apie save
                            informaciją Pirkėjas turėtų susipažinti su atitinkamo interneto tinklapio taisyklėmis,
                            Privatumo politika ir kitais dokumentais.<br />

                            Naudotojas (pirkėjas) gali atlikti pirkimo veiksmus prisiregistravęs prie www.placental.lt
                            elektroninės parduotuvės, taip pat neatlikęs registracijos.<br />
                        </h6>

                        <h4 className={'font-weight-light mt-5 mb-3'}>
                            Asmens duomenų teikimas, tvarkymo teisiniai pagrindai ir tiklsai
                        </h4>
                        <h6 className="font-weight-light text-justify"
                            style={{"line-height":"1.6"}}>
                            Informaciją, kuri yra renkama apie Naudotojus (pirkėjus), priklauso nuo Jums teikiamų
                            paslaugų ir to, kokią informaciją pateikiate internetinėje parduotuvėje placental.lt
                            pradedant naudotis paslaugomis ar registruojantis paskyroje. Jūsų asmens duomenys yra
                            gaunami šiais būdais:<br />

                            pateikiate Jūs (užpildydami ir pateikdami užklausos formą interneto svetainėje, skambindami
                            kontaktų centro telefono numeriu, lankydamiesi klientų aptarnavimo skyriuose);
                            generuojami automatiškai, kiek tai numato galiojantys teisės aktai (lankantis interneto
                            svetainėje ir / ar socialinėje paskyroje, naudojantis programėlėmis);
                            gaunami iš trečiųjų asmenų ir / ar viešai prieinamų šaltinių, kiek tai numato galiojantys
                            teisės aktai.
                            Pirkėjas gali atlikti pirkimo veiksmus prisiregistravęs prie . Pirkėjas yra atsakingas už
                            savo Asmens duomenų pateikimą, tikslumą ir teisingumą.<br />

                            Asmenys, norintys registruotis privalo pateikti savo elektroninį pašto adresą ir sugalvotą
                            Slaptažodį. Registracijos metu yra sukuriama Paskyra. Paskyroje Pirkėjas pats suveda savo
                            duomenis – vardą, pavardę, telefono numerį, elektroninio pašto adresą, todėl tik jis yra
                            atsakingas už šių duomenų teisingumą. Pirkėjui suteikiamas Vartotojo ID. Pirkėjas gali bet
                            kuriuo metu:<br />

                            koreguoti ir/ar pildyti Asmens duomenis Paskyroje. Už pakoreguotą ir/ar papildytą duomenų
                            teisingumą atsakingas Pirkėjas;
                            kreiptis į Pardavėją elektroninio pašto adresu info@placental.lt dėl Paskyros panaikinimo.
                            Pardavėjas renka ir toliau tvarko Jūsų asmens duomenis remiantis asmens duomenų apsaugą
                            reglamentuojančiuose teisės aktuose apibrėžtais teisėtais pagrindais:<br />

                            kai tvarkyti asmens duomenis Pardavėją įpareigoja galiojantys teisės aktai;<br />
                            siekiant sudaryti ir / ar vykdyti su Jumis sudarytą sutartį;<br />
                            Jūsų sutikimu;<br />
                            dėl Pardavėjo teisėtų interesų, kai Jūsų privatūs interesai nėra svarbesni.<br />
                            Pardavėjas tvarko asmens duomenis šiais tikslais:<br />

                            Paslaugų teikimo, kliento mokumo įvertinimo ir kredito rizikos valdymo, pažeistų Pardavėjo
                            teisių gynimo.<br />
                            Tinkamam paslaugų teikimui, paslaugų kokybės užtikrinimui.<br />
                            Tiesioginės rinkodaros*.<br />
                            *Jei Pirkėjas po Prekių ir/ar paslaugų įsigijimo nepageidauja, kad jo nurodyti Asmens
                            duomenys būtų naudojami Tiesioginės rinkodaros ar veiklos analizės tikslais, Pirkėjas turi
                            prisijungti prie savo Paskyros, esančios www.placental.lt ir Paskyros lange Paskyra,
                            skyriuje Naujienlaiškių prenumeratos, turi nuimti varnelę, esančią prie teiginio Bendras
                            prenumeravimas, bei paspausti nuorodą Išsaugoti. Taip pat visais atvejais Pirkėjas gali
                            rašyti elektroninį laišką adresu info@placental.lt arba skambinti už duomenų apsaugą
                            atsakingam asmeniui telefonu +37065688852 ir nurodyti, kad nepageidauja gauti elektroninių
                            laiškų ir/arba SMS žinučių, telefono skambučių. Elektroninių laiškų ir/arba SMS žinučių
                            siuntimas, skambinimas telefonu nutraukiamas tik Pirkėjui aiškiai išvardinus el. pašto
                            adresus ir/arba telefono numerius.<br />

                            Pardavėjas gali perduoti Jūsų asmens duomenis:<br />

                            duomenų tvarkytojams (paslaugų teikėjams), kurie teikia paslaugas ir tvarko Jūsų asmens
                            duomenis Pardavėjo, kaip duomenų valdytojo, vardu (pvz. IT paslaugų teikėjai, auditoriai,
                            reklamos agentūros, transporto paslaugų teikėjai ir pan.);<br />
                            kitiems tretiesiems asmenims, kai to reikalaujama pagal galiojančius teisės aktus arba kai
                            tai yra būtina siekiant apsaugoti Pardavėjo teisėtus interesus (pvz. teismai, valdžios ir
                            teisėsaugos institucijos ir pan.).<br />
                            </h6>

                        <h4 className={'font-weight-light mt-5 mb-3'}>
                            Asmens duomenų naudijimo ir saugojimo tvarka
                        </h4>
                        <h6 className="font-weight-light text-justify"
                            style={{"line-height":"1.6"}}>
                            Naudotojas (pirkėjas) suteikia teisę  rinkti, valdyti, tvarkyti ir saugoti Pirkėjo Asmens
                            duomenis tokia apimtimi ir tokiais tikslais, kaip numatyta Privatumo politikoje ir kituose
                            www.placental.lt dokumentuose.<br />

                            Pardavėjas saugo Naudotojų (pirkėjų) asmens duomenis tiek, kiek reikia nustatytam tikslui
                            pasiekti. Pasiekus nustatytą tikslą, Pirkėjų asmens duomenys ištrinami, išskyrus tuos
                            atvejus, kai galiojantys teisės aktai įpareigoja Pardavėją saugoti asmens duomenis šiuose
                            teisės aktuose nustatytą terminą. Pasibaigus šiam terminui, asmens duomenys ištrinami taip,
                            kad jų negalima būtų atgaminti.<br />

                            Pardavėjas vidinių organizacinių ir techninių priemonių pagalba užtikrina, jog Pirkėjo
                            pateikiami Asmens duomenys būtų apsaugoti nuo bet kokių neteisėtų veiksmų: neteisėto Asmens
                            duomenų pakeitimo, atskleidimo ar sunaikinimo, asmens tapatybės vagystės, sukčiavimo, bei
                            Asmens duomenų apsaugos lygio atitikimą Lietuvos Respublikos teisės aktų reikalavimams.<br />

                            Pirkėjas įsipareigoja ir privalo saugoti savo prisijungimo prie placental.lt slaptažodį ir
                            prisijungimo vardą, bei kitus duomenis. Pirkėjas įsipareigoja ir privalo jokiems kitiems
                            tretiesiems asmenims neatskleisti Asmens duomenų nei apie save, nei apie trečiuosius
                            asmenis, jeigu tokie trečiųjų asmenų Asmens duomenys jam tapo prieinami, ir iš karto apie
                            matomus pažeidimus informuoti Pardavėją.<br />
                        </h6>

                        <h4 className={'font-weight-light mt-5 mb-3'}>
                            Asmens duomenų subjektų teisių įgyvendinimas
                        </h4>
                        <h6 className="font-weight-light text-justify"
                            style={{"line-height":"1.6"}}>
                            Naudotojas (pirkėjas), kurio asmens duomenis tvarko Pardavėjas, turi šias teises:<br />
                            žinoti (būti informuotas) apie savo duomenų tvarkymą;<br />
                            susipažinti su savo asmens duomenimis ir kaip jie yra tvarkomi;<br />
                            reikalauti ištaisyti, sunaikinti savo asmens duomenis arba sustabdyti, išskyrus saugojimą,
                            savo asmens duomenų tvarkymo veiksmus, kai duomenys tvarkomi nesilaikant Lietuvos
                            Respublikos asmens duomenų teisinės apsaugos įstatymo ir kitų įstatymų nuostatų;<br />
                            nesutikti, kad būtų tvarkomi Jūsų asmens duomenys.<br />
                            Visos nurodytos asmens duomenų subjektų teisės bus įgyvendintos prieš tai nustačius ir
                            patikrinus asmens, kuris kreipiasi, tapatybę. Tai galite padaryti bet kuriame  klientų
                            aptarnavimo padalinyje arba pasinaudodami tam tikromis nuorodomis pateikiamomis
                            www.placental.lt ar Pardavėjo teikiamos reklaminio turinio medžiagos apačioje.<br />

                            Pardavėjas gali nesudaryti Jums sąlygų įgyvendinti aukščiau nurodytų asmens duomenų subjektų
                            teisių, kai galiojančių teisės aktų numatytais atvejais reikia užtikrinti nusikaltimų,
                            tarnybinės ar profesinės etikos pažeidimų prevenciją, tyrimą ir nustatymą, taip pat duomenų
                            subjekto ar kitų asmenų teisių ir laisvių apsaugą.<br />

                            Jei iškiltų klausimų dėl šioje privatumo politikoje pateiktos informacijos, maloniai
                            prašome kreiptis Jums patogiu būdu.<br />

                            Bet kokį prašymą ar  nurodymą, susijusį su Asmens duomenų tvarkymu, Pirkėjas turi pateikti
                            Pardavėjui raštu vienu iš šių būdų:<br />
                            1) įteikdamas tokį prašymą ar nurodymą Pardavėjui tiesiogiai adresas Mozūriškių g. 29,
                            Vilnius<br />
                            2) išsiųsdamas tokį prašymą ar nurodymą elektroninio pašto adresu info@placental.lt iš savo
                            elektroninio pašto adreso.<br />

                            Pardavėjas, gavęs tokį Pirkėjo prašymą ar nurodymą, ne vėliau kaip per 30 (trisdešimt)
                            kalendorinių dienų pateikia Pirkėjui atsakymą bei atlieka prašyme nurodytus veiksmus ar
                            atsisako juos atlikti. Pirkėjo prašymu atsakymas turi būti pateiktas raštu.<br />
                        </h6>

                        <h4 className={'font-weight-light mt-5 mb-3'}>
                            Slapukų naudojimas
                        </h4>
                        <h6 className="font-weight-light text-justify"
                            style={{"line-height":"1.6"}}>
                            Pirkėjui lankantis www.placental.lt, yra naudojami slapukai (angl. cookies). Tai nedideli
                            informacijos elementai, išsaugomi Jūsų interneto naršyklėje, kurie padeda atpažinti Jus kaip
                            ankstesnį interneto svetainės lankytoją, išsaugoti Jūsų lankymosi svetainėje istoriją ir
                            pagal tai pritaikyti turinį. Taip pat slapukai padeda užtikrinti sklandų  interneto
                            svetainės ir elektroninės prekyvietės veikimą, leidžia stebėti lankymosi šioje interneto
                            svetainėse trukmę, dažnumą bei rinkti statistinę informaciją apie šios interneto svetainės
                            lankytojų skaičių. Analizuodami šiuos duomenis galime tobulinti, padaryti ją patogesnę
                            Jūsų naudojimui.<br />

                            Pardavėjo naudojami Slapukai savarankiškai neidentifikuoja konkretaus asmens. Duomenys
                            surinkti slapukų pagalba daugiau susiję su konkretaus kompiuterio panaudojimu negu su
                            konkrečiu interneto naudotoju, tačiau Jūs galite atsisakyti slapukų naudojimo, naršyklėje
                            pasirinkdami tam tikrus nustatymus. Visgi atkreipiame Jūsų dėmesį į tai, kad tokiu atveju
                            Jūs negalėsite išnaudoti visų svetainės teikiamų funkcijų. Žemiau pateikiamas slapukų
                            sąrašas.<br />
                        </h6>
                    </div>
                </div>
            </div>
        )
}

export default withRouter(PrivacyPolicy)